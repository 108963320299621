.dialog-container {
  position: relative; /* Ensure this container is positioned */
}

.dialog-container .close-btn {
  position: absolute;
  right: 8px;
  top: 8px;
  color: #9e9e9e;
  transition: transform 0.3s ease-in-out, color 0.3s ease-in-out;
}

.dialog-container .close-btn:hover {
  transform: rotate(90deg);
  color: #616161;
}
.dialog-title {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: 2rem;
  font-weight: bolder;
}

.dialog-title-text {
  flex-grow: 1;
  text-align: center;
}
.slider-container {
  margin-right: 40px;
  width: 100%;
  margin-left: 40px;
  position: relative;
}
.slide-item {
  width: 100px !important;
  color: white;
  margin: 10px !important;
  font-size: 1.5em;
}
.slide-content {
  border: 2px solid #61616155;
  color: #616161;
  border-radius: 10px;
  width: 100px;
  height: 120px;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  flex-direction: column !important;
  justify-content: center;
  cursor: pointer; /* Change cursor to pointer to indicate clickable */
  transition: background-color 0.3s, color 0.3s, border 0.3s; /* Smooth transitions */
}
.booking_content {
  font-size: 1.2rem;
  font-family: 'Kanit', sans-serif;
  font-weight: 200;
  text-align: left;
  font-style: italic;
  letter-spacing: 1px;
  line-height: 1.5;
}
.slide-item-time {
  color: white;
  font-size: 1.5em;
}
.slide-content-time {
  border: 2px solid #61616155;
  color: #616161;
  border-radius: 10px;
  margin: 8px;
  padding: 10px;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  flex-direction: column !important;
  justify-content: center;
  cursor: pointer; /* Change cursor to pointer to indicate clickable */
  transition: background-color 0.3s, color 0.3s, border 0.3s; /* Smooth transitions */
}
.slide-content.clicked,
.slide-content-time.clicked {
  background-color: rgb(33, 140, 172);
  color: #fff;
  border: 0;
}
.day {
  display: inline-block;
}

.date {
  font-weight: bolder;
}

.color-indicator {
  height: 10px; /* Adjust height as needed */
  border-radius: 5px; /* Rounded corners */
}
.btn {
  color: #fff;
  font-weight: bolder;
  border-radius: 10px;
  background-color: rgb(33, 140, 172);
}
.btn:hover {
  background-color: rgb(41, 129, 155);
}
