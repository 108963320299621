@import url('https://fonts.googleapis.com/css2?family=Corben:wght@400;700&family=Dancing+Script:wght@400..700&family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.about_logo {
  width: 400px;
}

.about_content {
  font-size: 1.2rem;
  font-family: 'Kanit', sans-serif;
  font-weight: 200;
  text-wrap: balance;
  padding: 10px;
  font-style: italic;
  letter-spacing: 1px;
  line-height: 1.5;
}

.wrapper {
  position: relative;
  display: flex;
  justify-content: space-around;
  gap: 15px;
}

.container {
  width: 28vmin;
  height: 28vmin;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 10px;
  position: relative;
  margin-bottom: 2rem;
  background-color: #ffffff;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  text-align: center;
  transition: transform 0.3s ease-in-out;
}
.sub_container {
  border: dotted 4px #0a4870;
  border-radius: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.about_img {
  border-radius: 20px;
  width: 100%;
  height: auto;
  max-height: 50%;
  object-fit: cover;
}
.container:hover {
  transform: scale(1.05);
}
/* 
.icon {
  color: #0a4870;
  font-size: 600 !important;

  text-align: center;
} */
.icon {
}

span.num {
  color: #0a4870;
  font-weight: 600;
  font-size: 2.5em;
}

span.text {
  color: #0a4870;
  font-size: 1.2em;
  padding: 0.7em 0;
  font-weight: 600;
  text-transform: uppercase;
}

@media screen and (max-width: 1024px) {
  .wrapper {
    width: 90vw;
    flex-wrap: wrap;
    gap: 20px;
  }

  .container {
    width: calc(33.33% - 20px);
    height: 350px;
    font-size: 1.4em;
  }
}

@media screen and (max-width: 768px) {
  .wrapper {
    width: 90vw;
    flex-wrap: wrap;
    gap: 20px;
  }
  .about_logo {
    width: 250px;
  }

  .container {
    width: calc(50% - 20px);
    height: 350px;
    font-size: 1.6em;
  }
}

@media screen and (max-width: 480px) {
  .wrapper {
    gap: 15px;
  }

  .container {
    width: 100%;
    height: 350px;
    font-size: 1.4em;
  }
}
.hero {
  display: inline-block;
  position: relative;
  width: 500px;
  min-width: 200px;
  height: 500px;
  border-radius: 30px;
  overflow: hidden;
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.3);
  margin: 30px;
  transition: transform 0.5s ease, border-radius 0.5s ease-in-out;
}

.hero-profile-img {
  height: 75%;
  width: 100%;
  object-fit: cover;
}

.hero-description-bk {
  border-radius: 200px;
  position: absolute;
  top: 55%;
  left: -5px;
  height: 65%;
  width: 108%;
  transform: skew(20deg, -9deg);
  transition: transform 1.5s ease-in-out;
}
.hero:hover .hero-description-bk {
  transform: skew(-20deg, 5deg) scale(1.1) rotate(-5deg); /* Add scale and rotate */
  border-radius: 300px; /* Circular effect */
}
.second .hero-description-bk {
  border-radius: 200px;
  background-image: linear-gradient(
    90deg,
    hsla(165, 100%, 35%, 1) 0%,
    hsla(180, 68%, 23%, 1) 100%
  );
  /* background-image: linear-gradient(-20deg, #bb7413, #e7d25c); */
}

.hero-logo {
  color: #0a4870; /* This is for text color, may not affect an icon */
  height: 80px;
  width: 80px;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  bottom: 30%;
  left: 30px;
  overflow: hidden;
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}
.hero-logo svg, /* For SVG icons */
.hero-logo i {
  /* For font icons like FontAwesome */
  font-size: 4em; /* Adjust based on your icon size */
  color: inherit; /* Inherit the color from .hero-logo */
}
.hero-description {
  position: absolute;
  font-family: 'Roboto Monos', sans-serif;
  text-transform: uppercase;
  color: #fff;
  font-weight: 600;
  left: 150px;
  bottom: 26%;
}

.hero-btn {
  position: absolute;
  color: #fff;
  right: 30px;
  bottom: 10%;
  padding: 10px 20px;
  border: 1px solid #fff;
}

.hero-btn a {
  color: #fff;
}

.hero-date {
  position: absolute;
  color: #fff;
  left: 15px;
  bottom: 5%;
}

.btn i:before {
  width: 14px;
  height: 14px;
  position: fixed;
  color: #fff;
  background: #0077b5;
  padding: 10px;
  border-radius: 50%;
  top: 5px;
  right: 5px;
}
