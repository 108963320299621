@import url('https://fonts.googleapis.com/css2?family=Corben:wght@400;700&family=Dancing+Script:wght@400..700&display=swap');

body {
  height: 100%;
  margin: 0;
  padding: 0;
  /* background: radial-gradient(ellipse at bottom, #57868c 0%, #fff 100%); */
}
.title {
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 10px;
  font-weight: 300;
  font-size: 3rem;
  font-family: 'Roboto Monos', sans-serif;
  text-transform: uppercase;
  color: #0a4870;
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 3px;
    width: 50px;
    margin: auto;
    background: #0a4870;
  }
}
.title_info {
  position: relative;
  font-family: 'Roboto Monos', sans-serif;
  padding-bottom: 10px;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 1.5rem;
  text-transform: uppercase;
  color: #0a4870;
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 3px;
    width: 50px;
    margin: auto;
    background: #0a4870;
  }
}
p {
  text-wrap: balance;
}
.scroll-to-top-btn,
.scroll-to-top-btn-phone {
  display: block;
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #0a4870;
  color: white;
  border: none;
  border-radius: 50%;
  padding: 15px;
  font-size: 24px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  z-index: 9999;
  opacity: 0;
  transition:
    opacity 2s ease,
    transform 1.3s ease; /* Smooth transition */
}
.arrows {
  background-color: #0a4870;
  color: white;
  border: none;
  border-radius: 50%;
  padding: 15px;
  font-size: 24px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  z-index: 9999;
  opacity: 0.6;
  transition:
    opacity 2s ease,
    transform 1.3s ease; /* Smooth transition */
}

.scroll-to-top-btn-phone {
  bottom: 80px;
  right: 20px;
}
.scroll-to-top-btn.show,
.scroll-to-top-btn-phone.show {
  opacity: 0.5; /* Visible when scrolled down */
  pointer-events: auto;
  transition:
    opacity 2s ease,
    transform 1.3s ease; /* Smooth transition */
}

.scroll-to-top-btn:hover,
.scroll-to-top-btn-phone:hover,
.arrows:hover {
  opacity: 1;
}

.scroll-to-top-btn:active,
.scroll-to-top-btn-phone:active {
  transform: scale(0.95); /* Shrink slightly on click */
}
