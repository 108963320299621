.appBar {
  position: fixed !important;
  z-index: 1000 !important;
  background-color: rgba(30, 30, 30, 0.6) !important;
}
.toolbar_Button {
  text-transform: uppercase;
  color: white !important;
  padding: 15px !important;
  font-size: 1rem !important;
  font-family: 'Kanit', serif !important;
  font-weight: 300 !important;
  margin: 0 10px;
  letter-spacing: 2px;
  text-decoration: none;
}
.toolbar_logo {
  width: 100%;
  height: 40px;
}

.menuButton {
  display: none !important;
}

@media screen and (max-width: 1150px) {
  .toolbar_menu {
    display: none !important;
  }
  .menuButton {
    display: block !important;
  }
  .toolbar_logo {
    max-width: 100px;
    height: 40px;
  }
}
