@import url('https://fonts.googleapis.com/css2?family=Corben:wght@400;700&family=Dancing+Script:wght@400..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&display=swap');

.contact_content {
  font-size: 1.5rem;
  margin: 0;
  padding: 0;
  font-weight: 200;
  font-family: 'Kanit', serif;
}
.shadow {
  box-shadow: 0px 20px 50px -10px rgba(0, 0, 0, 0.2);
}
.offer_container {
  text-align: justify;
}
$font: 'Sofia', sans-serif;
$font-size: 16px;
$blue: #0a4870;
$blue2: #e3ebf1;
$black: #000;
$grey: #7d7d7d;
$grey2: #f0f0f0;
$grey3: #e8e7e7;
$grey4: #fdfdfd;
$bluegrey: #49606e;
$orange: #ec992c;

@mixin radius($val) {
  -webkit-border-radius: $val;
  -moz-border-radius: $val;
  border-radius: $val;
}

@mixin cardsOpen() {
  &::before {
    background: rgba(10, 72, 112, 0.6);
  }
  .book-container {
    .content {
      opacity: 1;

      transform: translateY(0px);
    }
  }

  .informations-container {
    transform: translateY(0px);
    .more-information {
      opacity: 1;
    }
  }
}

h2,
h3,
h4,
h5 {
  color: #0a4870;
  font-weight: 500;
}

ul {
  display: flex;
  flex-wrap: wrap;

  list-style: none;
  padding: 0;
  .about-card,
  .booking-card {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 20px;
    margin-bottom: 30px;
    @include radius(10px);
    overflow: hidden;

    background-position: center center;
    background-size: cover;

    text-align: center;
    color: $blue;

    transition: 0.3s;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      background: rgba(10, 72, 112, 0);

      transition: 0.3s;
    }

    .book-container {
      height: 200px;
      .content {
        position: relative;
        opacity: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        transform: translateY(-200px);
        transition: 0.3s;

        .btn {
          border: 3px solid white;
          padding: 10px 15px;
          background: none;
          text-transform: uppercase;
          font-weight: bold;
          font-size: 1.3em;
          color: white;
          cursor: pointer;
          transition: 0.3s;
          &:hover {
            background: white;
            border: 0px solid white;
            color: $blue;
          }
        }
      }
    }
    .btn_more {
      border: 3px solid white;
      padding: 10px 15px;
      background: none;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 1rem;
      color: $blue;
      cursor: pointer;
      transition: 0.3s;
      &:hover {
        background: white;
        border: 0px solid white;
      }
    }
    .informations-container {
      padding: 20px;
      height: 450px;
      background: $grey2;
      transform: translateY(206px);
      transition: 0.3s;

      .price {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bolder;
        margin-top: 10px;

        .icon {
          margin-right: 10px;
        }
      }

      .more-information {
        opacity: 0;

        transition: 0.3s;
        .info-and-date-container {
          display: flex;

          .box {
            flex: 1 0;
            padding: 15px;
            margin-top: 20px;
            @include radius(10px);
            background: white;
            font-weight: bold;
            font-size: 0.9em;

            .icon {
              margin-bottom: 5px;
            }

            &.info {
              margin-right: 10px;
            }
          }
        }
        .disclaimer {
          margin-top: 20px;
          font-size: 1rem;
          text-align: left;
          color: $grey;
          padding: '5px';
        }
      }
    }

    &:hover {
      @include cardsOpen();
    }
  }
}

@media (max-width: 768px) {
  ul {
    .booking-card {
      @include cardsOpen();
    }
  }
  .contact_content {
    font-size: 1rem;
    margin: 0;
    padding: 0;
    font-weight: 200;
    font-family: 'Kanit', serif;
  }
  .disclaimer {
    margin-top: 20px;
    font-size: 0.9rem;
    text-align: left;
    color: $grey;
    padding: '5px';
  }
}

.credits {
  display: table;
  background: $blue;
  color: white;
  line-height: 25px;

  margin: 10px auto;
  padding: 20px;

  @include radius(10px);

  text-align: center;

  a {
    color: $blue2;
  }
}

h1 {
  margin: 10px 20px;
}
