.header {
  position: relative;
  text-align: center;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  background-image: url('../uploads/main_background.jpg');
  color: white;
}
@media only screen and (max-device-width: 768px) {
  .header {
    background-attachment: scroll;
  }
}
.logo {
  width: 50px;
  fill: white;
  padding-right: 15px;
  display: inline-block;
  vertical-align: middle;
}

.inner-header {
  height: 65vh;
  margin: 0;
  padding: 0;
}

.waves {
  position: relative;
  width: 100%;
  height: 15vh;
  margin-bottom: -7px;
  min-height: 100px;
  max-height: 150px;
}

.parallax > use {
  animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}
.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}
.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}
.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}
@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
}
@media (max-width: 768px) {
  .waves {
    height: 40px;
    min-height: 40px;
  }
  .content {
    height: 30vh;
  }
  h1 {
    font-size: 24px;
  }
  .maincard_logo {
    display: none;
  }
}

.main_card {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}
.subtitle,
.subtitle-1 {
  color: white;
  margin-bottom: 10px;
  font-family: 'Roboto Monos', sans-serif;
  font-weight: 600;
  letter-spacing: 2px;
}
.subtitle {
  text-transform: uppercase;
}

.maincard_logo {
  width: 50%;
  min-width: 450px;
  max-width: 700px;
  filter: brightness(0) invert(1);
  margin-bottom: 50px;
}
@media screen and (max-width: 1150px) {
  .mainCardLeft {
    display: none;
  }
}
