@import url('https://fonts.googleapis.com/css2?family=Corben:wght@400;700&family=Dancing+Script:wght@400..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&display=swap');

.blob_fill {
  fill: linear-gradient(
    90deg,
    hsla(165, 100%, 35%, 1) 0%,
    hsla(180, 68%, 23%, 1) 100%
  );
}
.team_signatures {
  font-family: 'Dancing Script', cursive;
  font-optical-sizing: auto;
  font-size: 4rem;
  font-weight: 500;
  font-style: normal;
  text-align: left;
}
.team_position {
  font-family: 'Dancing Script';
  font-optical-sizing: auto;
  text-wrap: balance;
  font-size: 1.5rem;
  font-weight: 500;
  font-style: normal;
  margin-bottom: 15px;
}

.team_container .team_content_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-right: 55px;
  padding-left: 55px;
}

.team_container:nth-child(odd) .team_content_container {
  align-items: end;
}
.team_container:nth-child(odd) {
  flex-direction: row-reverse;
}
.team_container {
  text-align: left;
}
.team_content {
  font-size: 1.2rem;
  font-family: 'Kanit', sans-serif;
  font-weight: 200;
  font-style: italic;
  letter-spacing: 1px;
  line-height: 1.5;
}
.svg-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.blob {
  display: block;
  width: 100%;
  height: auto;
  animation: shine 30s infinite ease-in-out;
}

.person_image {
  position: absolute;
  bottom: 35%;
  left: 35%;
  width: 50%;
  z-index: 1;
  mask-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.1) 0%,
    rgba(0, 0, 0, 1) 15%
  );
  -webkit-mask-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.1) 0%,
    rgba(0, 0, 0, 1) 15%
  );
}
.read-more-btn {
  display: none !important;
}
@media screen and (max-width: 768px) {
  .team_signatures {
    font-family: 'Dancing Script', cursive;
    font-optical-sizing: auto;
    font-size: 2rem;
    font-weight: 500;
    font-style: normal;
  }
  .team_container .team_content_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-right: 55px;
    padding-left: 55px;
    justify-content: flex-start;
    overflow: hidden;
    height: 300px;
  }
  .team_container {
    height: 100%;
  }

  .read-more-btn {
    display: flex !important;
    flex-direction: 'column';
    align-items: 'center';
    color: #0a4870;
    cursor: 'pointer';
    transition: background-color 0.3s;
  }
  .team_container .team_content_container.expanded {
    height: auto;
  }
}
@keyframes shine {
  0% {
    filter: drop-shadow(0 0 0 rgba(0, 0, 0, 0.5));
  }
  50% {
    filter: drop-shadow(0 0 20px rgba(255, 255, 255, 0.8));
  }
  100% {
    filter: drop-shadow(0 0 0 rgba(0, 0, 0, 0.5));
  }
}
